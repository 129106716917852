<!-- 
    This compoenent is responsible for creating a record in Queue table to export the table,
    and notifying the user about the progress using ToastMessages component
    APIs:   initializeRequest: create a jobId in Queue table
            getStatus: get the status of the service 
-->
<template>
    <div class="iDatExport-container p-4">
        <p class="iDatExport-desc mb-4">
            Generate an MS Access database export of
            {{ selectedProjectName }}.<br />This process may take a while
            depending on the size of the project.<br />The completed file will
            be available in File manager.
        </p>
        <div id="iDatExport-button">
            <button @click="export_iDat" class="btn buttonSelect">
                Export iDAT database
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { datasetService } from '../../services/dataset.service';
import { commonService } from '../../services';
export default {
    name: 'iDatExportSidebar',
    data() {
        return {
            queueService: 'iDAT Export',
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        async downloadFile(docId) {
            var response = await commonService.getDocumentLinkByID(docId);
            return response;
        },
        async getFileName(docId) {
            var response = await commonService.getDocument(docId);
            return response.data.FileName;
        },
        async export_iDat() {
            try {
                const response = await datasetService.initializeRequest(
                    this.ProjectID,
                    'iDATExport'
                );
                const jobId = response.data.Data;

                await this.checkStatusUntilComplete(jobId);
            } catch (err) {
                this.setToastMessage({
                    queueService: this.queueService,
                    queueState: 'error',
                    queueMessage: 'failed!',
                    visible: true,
                    downloadLink: '',
                });
            }
        },

        async checkStatusUntilComplete(jobId) {
            let continueLoop = true;

            try {
                while (continueLoop) {
                    const statusResponse = await datasetService.getStatus(
                        jobId
                    );
                    const status = statusResponse.data;
                    switch (status) {
                        case 'Completed':
                            var response = await datasetService.getQueue(jobId);
                            var functionReturnStatus = JSON.parse(
                                response.data.FunctionReturnStatus
                            );
                            var docId = +functionReturnStatus.Data;

                            var docHref = await this.downloadFile(docId);
                            var fileName = await this.getFileName(docId);
                            var link = await commonService.getDownloadLink(
                                docHref,
                                fileName
                            );

                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'complete',
                                queueMessage: 'completed!',
                                downloadLink:
                                    '<a href="' +
                                    link +
                                    '" download="' +
                                    fileName +
                                    '" > Download ' +
                                    fileName +
                                    ' </a>',
                                visible: true,
                            });
                            continueLoop = false;
                            break;
                        case 'Error':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: 'failed!',
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                        case 'Unkown JobID':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: 'failed!',
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                        case 'Queued':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'queue',
                                queueMessage: 'in queue!',
                                visible: true,
                                downloadLink: '',
                            });
                            break;
                        case 'Processing':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'progress',
                                queueMessage: 'in progress!',
                                visible: true,
                                downloadLink: '',
                            });
                            break;
                        default:
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: status,
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                    }

                    await new Promise((resolve) => setTimeout(resolve, 30000));
                }
            } catch (error) {
                this.setToastMessage({
                    queueService: this.queueService,
                    queueState: 'error',
                    queueMessage: error,
                    visible: true,
                    downloadLink: '',
                });
            }
        },
    },

    computed: {
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
        ...mapState({
            selectedProjectName: function(state) {
                return state.projects.selectedProjects[0].ProjectName;
            },
        }),
    },
};
</script>

<style scoped></style>
